import { Controller } from '@hotwired/stimulus'
import { FloatingLogosConfig } from '../floating_logos_config'
import { shuffle } from '../utilities'
import PerlinNoise from '../perlin_noise'

const BUBBLE_CLASSES = [
  '[background-size:1468.625rem_9.5rem]',
  'absolute',
  'bg-white',
  'bg-[url(/images/logos/third-party/customers-sprite-sheet.png)]',
  'bg-no-repeat',
  'hover:z-50',
  'group',
  'opacity-0',
  'rounded-full',
  'scale-0',
  'size-[9.5rem]'
]

const TOOLTIP_CLASSES = [
  'absolute',
  'bg-gray-800',
  'block',
  'bottom-[-2.75rem]',
  'duration-250',
  'font-semibold',
  'group-hover:-translate-x-1/2',
  'group-hover:opacity-100',
  'left-1/2',
  'opacity-0',
  'overflow-hidden',
  'p-[0.4em_0.8em]',
  'pointer-events-none',
  'rounded-full',
  'select-none',
  'text-center',
  'text-white',
  '[transform:translateX(-50%)_translateY(-6px)]',
  'whitespace-nowrap'
]

export default class extends Controller {
  static targets = ['logoCtr']
  static values = { companies: { type: Object } }

  connect() {
    this.step = this.step.bind(this)
    this.bubbles = FloatingLogosConfig.bubbles
    this.logos = []

    for (const companyID in this.companiesValue) {
      if (Object.hasOwnProperty.call(this.companiesValue, companyID)) {
        const companyName = this.companiesValue[companyID]
        this.logos[Object.keys(this.companiesValue).indexOf(companyID)] = {
          tooltip: companyName,
          cssClass: `logo--${companyID}`
        }
      }
    }

    this.logos = shuffle(this.logos)

    if (!(this.logos.length > this.bubbles.length + 1)) {
      console.error('Logos array must have at least one more item then bubbles')
    }

    this.containerHeight = FloatingLogosConfig.containerHeight
    this.containerWidth = FloatingLogosConfig.containerWidth
    this.introDelay = FloatingLogosConfig.introDelay
    this.introDuration = FloatingLogosConfig.introDuration
    this.maxShrink = FloatingLogosConfig.maxShrink
    this.noiseScale = FloatingLogosConfig.noiseScale
    this.noiseSpeed = FloatingLogosConfig.noiseSpeed
    this.scrollSpeed = FloatingLogosConfig.scrollSpeed
    this.noiseT = 0
    this.scrollX = 0
    this.firstTick = null
    this.lastTick = 0
    this.vertShink = 0
    this.perlin = new PerlinNoise()
    this.verticalShrink()
    this.initBubbles()
    this.step(0)
  }

  adjustForWindowHeight(y) {
    const shrink = this.vertShrink * this.maxShrink
    return y * (1 - shrink) + (this.containerHeight / 2) * shrink
  }

  calcVertShrink(e, n, windowHeight) {
    return (windowHeight - e) / (n - e)
  }

  createBubbleEl() {
    const bubble = document.createElement('bubble')
    const tooltip = document.createElement('tooltip')
    bubble.classList.add(...BUBBLE_CLASSES)
    tooltip.classList.add(...TOOLTIP_CLASSES)
    bubble.appendChild(tooltip)
    this.logoCtrTarget.appendChild(bubble)
    return bubble
  }

  getLogo(el) {
    return { cssClass: el.dataset.logo, tooltip: el.childNodes[0].textContent }
  }

  initBubbles() {
    this.bubbles.forEach(bubble => {
      bubble.seedX = 1e4 * Math.random()
      bubble.seedY = 1e4 * Math.random()
      bubble.noiseX = bubble.noiseY = 0
      bubble.introDelay = Math.random() * this.introDelay
      bubble.introProgress = 0
      if (!bubble.el) bubble.el = this.createBubbleEl()
      this.updateBubble(bubble)
      const logo = this.randomLogo()
      this.setLogo(bubble.el, logo)
    })
  }

  introProgressFunc(e) {
    return (e < .5) ? (2 * e * e) : ((4 - (2 * e)) * e - 1)
  }

  minMax(a, min, max) {
    return Math.max(Math.min(a, max), min)
  }

  randomLogo(logo = undefined) {
    if (logo) {
      this.logos.push({ cssClass: logo.cssClass, tooltip: logo.tooltip })
    }

    return this.logos.shift()
  }

  setLogo(el, logo) {
    const existingClass = el.dataset.logo
    if (existingClass) el.classList.remove(existingClass)
    el.classList.add(logo.cssClass)
    el.dataset.logo = logo.cssClass
    el.childNodes[0].textContent = logo.tooltip
  }

  step(tick) {
    if (!this.firstTick) this.firstTick = tick
    tick -= this.firstTick
    this.tickLength = tick - this.lastTick
    this.lastTick = tick
    this.scrollX -= this.tickLength * this.scrollSpeed
    this.noiseT += this.tickLength * this.noiseSpeed

    this.bubbles.forEach(bubble => {
      bubble.noiseX = this.perlin.getVal(bubble.seedX + this.noiseT)
        * this.noiseScale - (this.noiseScale / 2)

      bubble.noiseY = this.perlin.getVal(bubble.seedY + this.noiseT)
        * this.noiseScale - (this.noiseScale / 2)

      if (tick > bubble.introDelay && bubble.introProgress < 1) {
        const progress =
          bubble.introProgress + this.tickLength / this.introDuration

        bubble.introProgress = Math.min(1, progress)
      }

      this.updateBubble(bubble)
    })

    window.requestAnimationFrame(this.step)
  }

  updateBubble(b) {
    const x = b.x + b.noiseX + this.scrollX
    let y = b.y + b.noiseY
    y = this.adjustForWindowHeight(y)

    if (x < -200) {
      b.x += this.containerWidth
      const newLogo = this.randomLogo(this.getLogo(b.el))
      this.setLogo(b.el, newLogo)
    }

    let s = b.s || 1
    s *= this.introProgressFunc(b.introProgress) / 20 + .95
    b.el.style.opacity = this.introProgressFunc(b.introProgress)
    b.el.style.transform = `translate(${x}px, ${y}px) scale(${s})`
  }

  verticalShrink() {
    const vertShrink = this.calcVertShrink(1e3, 800, window.innerHeight)
    this.vertShrink = this.minMax(vertShrink, 0, 1)
  }
}
